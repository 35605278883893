import React from "react"
import { Helmet } from "react-helmet"

const IndexMeta = () => {
    return (
        <Helmet>
            <meta charset="utf-8" />
            <link rel="apple-touch-icon" sizes="180x180" href="images/benjamtech/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="images/benjamtech/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="images/benjamtech/favicon-16x16.png" />
            <link rel="mask-icon" href="images/benjamtech/safari-pinned-tab.svg" color="#5bbad5" />
            <link rel="manifest" href="site.webmanifest" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />

            <meta
                name="keywords"
                content="BenjamTech,benjam.tech,benjamtech,Programming,software development,webdev,web developer, dev,Clipboard Manager,Database,Technology"
            />
            <meta name="author" content="BenjamTech" />
            <title>BenjamTech, technology and software</title>
            <meta
                name="description"
                content="BenjamTech: I love technology and programming. Check out my social media profiles. Instagram: benjamtech Twitter: benjamtech Github: benjamtech"
            />

            /* Google / Search Engine Tags */
            <meta itemprop="name" content="BenjamTech" />
            <meta
                itemprop="description"
                content="BenjamTech: I love technology and programming. React.js is my favorite library right now. Check out my social media profiles. Instagram: benjamtech Twitter: benjamtech Github: benjamtech"
            />
            <meta itemprop="image" content="https://benjam.tech/images/benjamtech/avatar.png" />
            /* Facebook Meta Tags */
            <meta property="og:url" content="https://benjam.tech" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="BenjamTech" />
            <meta
                property="og:description"
                content="BenjamTech: I love technology and programming. React.js is my favorite library right now. Check out my social media profiles. Instagram: benjamtech Twitter: benjamtech Github: benjamtech"
            />
            <meta property="og:image" content="https://benjam.tech/images/benjamtech/avatar.png" />
            /* Twitter Meta Tags */
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="BenjamTech" />
            <meta
                name="twitter:description"
                content="BenjamTech: I love technology and programming. React.js is my favorite library right now. Check out my social media profiles. Instagram: benjamtech Twitter: benjamtech Github: benjamtech"
            />
            <meta name="twitter:image" content="https://benjam.tech/images/benjamtech/avatar.png" />
            <link rel="alternate" href="http://benjam.tech" hreflang="en" />
        </Helmet>
    )
}


const QuickPasteDbMeta = () => {
    return (
        <Helmet>
            <meta charset="utf-8" />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/images/quickpastedb/apple-touch-icon.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/images/quickpastedb/favicon-32x32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/images/quickpastedb/favicon-16x16.png"
            />
            <link rel="manifest" href="/images/quickpastedb/site.webmanifest" />
            <link rel="shortcut icon" href="/images/quickpastedb/favicon.ico" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />


            <meta
                name="keywords"
                content="QuickPasteDb, quick paste db, text expansion, snippet library, code snippet, clipboard tool, clipboard manager, local database, fussy search"
            />
            <meta name="author" content="BenjamTech" />
            <title>QuickPasteDb - Don't waste time repeating yourself</title>
            <meta
                name="description"
                content="QuickPasteDb is a simple tool to paste content. It's a kind of text expansion tool and snippet library. Kind of Local database and Clipboard manager."
            />
            
            /* Google / Search Engine Tags */
            <meta itemprop="name" content="BenjamTech" />
            <meta
                itemprop="description"
                content="QuickPasteDb is a simple tool to paste content. It's a kind of text expansion tool and snippet library. Kind of Local database and Clipboard manager."
            />
            <meta
                itemprop="image"
                content="https://benjam.tech/images/quickpastedb/Startup_Screen.png"
            />
            /* Facebook Meta Tags */
            <meta property="og:url" content="https://benjam.tech/quickpastedb" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="BenjamTech" />
            <meta
                property="og:description"
                content="QuickPasteDb is a simple tool to paste content. It's a kind of text expansion tool and snippet library. Kind of Local database and Clipboard manager."
            />
            <meta
                property="og:image"
                content="https://benjam.tech/images/quickpastedb/Startup_Screen.png"
            />
            /* Twitter Meta Tags */
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="BenjamTech" />
            <meta
                name="twitter:description"
                content="QuickPasteDb is a simple tool to paste content. It's a kind of text expansion tool and snippet library. Kind of Local database and Clipboard manager."
            />
            <meta
                name="twitter:image"
                content="https://benjam.tech/images/quickpastedb/Startup_Screen.png"
            />
            <link rel="alternate" href="https://benjam.tech/quickpastedb" hreflang="en" />
        </Helmet>
    )
}


export {
    IndexMeta,
    QuickPasteDbMeta,
}