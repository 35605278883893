import React, { useState, useEffect } from "react"
import "../styles/rootstyle.css"
import {
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoGithub,
  IoMdContrast,
} from "react-icons/io"
import Avatar from "../images/benjamtech/avatar.png"
import AvatarInvert from "../images/benjamtech/avatarinvert.png"
import styles from "../styles/index.module.css"
import { Router, Location } from "@reach/router"
import { IndexMeta } from "../components/metatags"
import { Link } from "gatsby";

export default () => {
  return (
    <Location>
      {({ location }) => (
        <Router location={location} className="router">
          <Index path="/" inviteLink={undefined} />
        </Router>
      )}
    </Location>
  )
}

function Index() {
  const [isDark, setIsDark] = useState(false);

  const resizeToMax = id => {
    let myImage = new Image()
    var img = document.getElementById(id)
    if (
      myImage.width / document.body.clientWidth >
      myImage.height / document.body.clientHeight
    ) {
      img.style.width = "100%"
    } else {
      img.style.width = "100%"
    }
  }

  useEffect(() => {
    changeColors(false)
  }, [])

  const cssVar = (name, value) => {
    // eslint-disable-next-line
    if (name[0] != "-") name = "--" + name //allow passing with or without --
    if (value) document.documentElement.style.setProperty(name, value)
    return getComputedStyle(document.documentElement).getPropertyValue(name)
  }

  const changeTheme = async () => {
    changeColors(!isDark);
    setIsDark(!isDark);
  }

  const changeColors = isDark => {
    if (isDark) {
      cssVar("--background", "#000000")
      cssVar("--background-avatar", "#130F0E")
      cssVar("--text", "#fff")
      cssVar("--icon", "#fff")
      cssVar("--primary", "#C79188")
    } else {
      cssVar("--background", "#fff")
      cssVar("--background-avatar", "#ECF0F1")
      cssVar("--text", "#000")
      cssVar("--icon", "#000")
      cssVar("--primary", "#386E77")
    }
  }

  return (
    <div>
      <IndexMeta />
      <button
        aria-label="Change theme"
        className={styles.themeBtn}
        onClick={changeTheme}
      >
        <IoMdContrast />
      </button>

      <div className={styles.container}>
        <div className={styles.avatar}>
          <img
            alt=""
            id="imgAvatar"
            style={{ verticalAlign: "text-bottom" }}
            src={isDark ? AvatarInvert : Avatar}
            onLoad={() => {
              resizeToMax("imgAvatar")
            }}
          />
        </div>

        <div className={styles.content}>
          <div />

          <div>
            <h1>I am BenjamTech</h1>
            <p>
              Just your friendly neighbourhood programmer
              </p>
            <div className={styles.icons}>
              <a
                href="https://twitter.com/benjamtech"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoTwitter className={styles.icon} />
              </a>
              <a
                href="https://www.instagram.com/benjamtech/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoInstagram className={styles.icon} />
              </a>
              <a
                href="https://github.com/benjamtech"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoGithub className={styles.icon} />
              </a>
            </div>
          </div>

          <div className={styles.project}>
            <div>
              <p>Call it a résumé:</p>
              <a href="https://sevenclip.com/" target="_blank" rel="noreferrer">
                SevenClip
                </a>

              <Link to="/rocket">Rocket</Link>

              <a href="https://cv.benjam.tech" target="_blank" rel="noreferrer">
                BenjamTech CV
                </a>



            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
